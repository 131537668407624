import React from "react"

const Content = () => {
  return (
    <section className="privacy-policy-content">
      <div className="container-fluid">
        <h2>
          Informacja na temat
          <br />
          ryzyka inwestycyjnego
        </h2>

        <h3>
          I. Brak objęcia systemem gwarancji depozytów/Ryzyko utraty kwoty
          pożyczki
        </h3>
        <p>
          Informujemy, że pożyczki nie są objęte systemem gwarancji depozytów
          ustanowionym zgodnie z dyrektywą 2014/49/UE, ani systemem rekompensat
          dla Inwestorów ustanowionym zgodnie z dyrektywą 97/9/WE. Oznacza to,
          że w razie niewypłacalności Spółki Deweloperskiej, Pożyczkodawca nie
          otrzyma zwrotu zainwestowanych środków z Bankowego Funduszu
          Gwarancyjnego. Udzielenie pożyczki wiąże się z ryzykiem braku
          odzyskania środków. Finansowany projekt inwestycyjny znajduje się na
          wczesnym etapie zaawansowania – w związku z czym Inwestor musi liczyć
          się z ryzykiem braku uzyskania odsetek, a nawet utraty całego
          zainwestowanego kapitału.
        </p>

        <h3>II. Ryzyko zdarzeń losowych (niezależnych od Inwestora)</h3>
        <p>
          Na realizację projektu mogą wpłynąć zdarzenia losowe. Należy tu
          wymienić zdarzenia określane mianem tzw. siły wyższej (klęski
          żywiołowe, wojna, pandemie, czy ataki terrorystyczne). Może również
          dojść do niekorzystnych działań osób trzecich (np. zaskarżanie decyzji
          administracyjnych związanych z projektem budowlanym, czy spory z
          właścicielami sąsiednich nieruchomości), co mogłoby skutkować
          opóźnieniem realizacji inwestycji.
        </p>

        <h3>
          III. Ryzyko zmiany sytuacji na rynku nieruchomości i zmiany stanu
          prawnego
        </h3>
        <p>
          Na realizację projektu deweloperskiego i zysk z takiego
          przedsięwzięcia ma wpływ sytuacja na rynku nieruchomości. Istnieje
          ryzyko związane ze zmniejszeniem popytu na rynku oraz wzrostem cen
          materiałów i usług budowlanych. Ze względu na ewentualne komplikację
          okres realizacji przedsięwzięcia deweloperskiego może się wydłużyć, a
          ostateczny zysk z przedsięwzięcia deweloperskiego być mniejszy niż
          zamierzony. Zmiana stanu prawnego w zakresie udzielania kredytów i
          polityki banków może wpłynąć na dostępność kredytów hipotecznych, co
          również miałoby wpływ na popyt na nieruchomości. Nie można również
          wykluczyć zmian przepisów niekorzystnych dla branży deweloperskiej, w
          tym utrudnień w pozyskiwaniu finansowania inwestycji deweloperskich.
          Na zysk z inwestycji mogą mieć również wpływ ewentualne zmiany prawa
          podatkowego, w szczególności zmierzające do zwiększenia opodatkowania
          zysków z takich inwestycji.
        </p>
        <p>
          Możliwe jest również nałożenie na deweloperów dodatkowych obowiązków i
          zmiany przepisów zmierzających do ograniczenia zakresu i zasad
          funkcjonowania tzw. crowdlendingu i finansowania przedsięwzięć poprzez
          zawieranie korzystnie oprocentowanych umów pożyczek.
        </p>

        <h3>IV. Możliwe ryzyka operacyjne i ryzyka po stronie Inwestorów</h3>
        <p>
          Na skutek działania siły wyższej i innych okoliczności niezależnych od
          uczestników projektu, w tym m.in. pogorszenia się sytuacji
          ekonomicznej Spółki deweloperskiej, utraty płynności finansowej lub
          wypłacalności, ogłoszenia upadłości lub otwarcia postępowania
          restrukturyzacyjnego istnieje ryzyko niewywiązania się Spółki
          deweloperskiej ze zobowiązań wynikających z zawartej z Inwestorem
          umowy.
        </p>

        <h3>V. Ryzyko inflacji</h3>
        <p>
          Wypłata odsetek oraz odzyskanie kapitału dotknięte są ryzykiem
          inflacji, które może wpływać na rzeczywisty zwrot na kapitale, lub też
          w sytuacjach krańcowych doprowadzić do zwrotu ujemnego, ze względu na
          możliwą znaczną dewaluacje wartości pieniądza
        </p>

        <h3>VI. Ryzyko braku możliwości wyjścia z inwestycji</h3>
        <p>
          Umowy pożyczki są zawierane na konkretny okres czasu, a Inwestor nie
          będzie uprawniony do wcześniejszego żądania dokonania zwrotu kwoty
          pożyczki od Dewelopera, w związku z czym Pożyczkodawca musi liczyć się
          z brakiem możliwości wcześniejszego odzyskania zainwestowanego
          kapitału.
        </p>

        <p>
          Pomimo dołożenia przez spółką najwyższej staranności przy ocenie
          ryzyka planowanej inwestycji przedstawione powyżej ryzyka mogą być
          niewyczerpujące. Z uwagi na możliwość wystąpienia nieprzewidzianych
          zdarzeń, zmian na rynku nieuwzględnionych przy dokonywaniu analizy
          stwierdzenia dotyczące przyszłości są obarczone ryzykiem i
          niepewnością.
        </p>
      </div>
    </section>
  )
}

export default Content
